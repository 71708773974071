import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import DSE_DE from "../../static/PDF/DSE_DE_V1.00.pdf"
import style from "./style.module.scss"

import Layout from "components/Layout/de"

export default function(props) {
  const header = {
    text: "MoonVision Datenschutzerklärung",
    description:
      "Ihre Privatsphäre ist uns wichtig. Daher sind wir dahingehend transparent und informieren Sie darüber, wie wir Informationen sammeln, verwenden und weitergeben.",
  }
  return (
    <Layout header={header} location={props.location}>
      <main class="white-background">
        <Container>
          <Row className="py-4">
            <Col md="8" className="py-3">
              <h1>Datenschutzerklärung</h1>
            </Col>
            <Col md="8" className="py-3">
              <p>
                Ihre Privatsphäre ist uns wichtig. Daher sind wir dahingehend
                transparent und informieren Sie darüber, wie wir Informationen
                sammeln, verwenden und weitergeben.
                <a
                  className={style.linkInsideParagraph}
                  rel="noopener noreferrer"
                  href={DSE_DE}
                  target="_blank"
                >
                  {" "}
                  Aktuelle Version
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}
